import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import {
  isSanityBlogPreview,
  isSanityCategoryPanelPreview,
  isSanityColumnContent,
  isSanityCtaBlock,
  isSanityLearnCategoryPreview,
  isSanityProductReferenceCta,
  isSanityRecipePreview,
} from "@util/types";
import CtaBlock from "@components/global/ctaBlock";
import ProductReferenceCta from "@components/global/productReferenceCta";
import CategoryPanelPreview from "@components/global/categoryPanelPreview";
import ColumnContent from "@components/global/columnContent";
import LearnCategoryPreview from "@components/global/learnCategoryPreview";

import BlogPreview from "@components/global/blogPreview";
import Hero from "@components/global/hero";
import RecipePreview from "@components/global/recipePreview";
import { useStore } from "@state/store";
import SEO from "@shared/seo";
import Layout from "@shared/layout";

interface Props extends PageProps {
  data: Query;
}

const IndexPage = ({ data }: Props) => {
  if (data.allSanityHomePage == null) {
    return null;
  }
  const { currentSearch, headerSearch } = useStore();
  const pageContent = data.allSanityHomePage.nodes[0].pageContent;
  const hero = data.allSanityHomePage.nodes[0].hero;
  const seo = data.allSanityHomePage.nodes[0].seo;

  useEffect(() => {
    if (currentSearch || headerSearch) {
      useStore.setState({ currentSearch: "", headerSearch: "" });
    }
  }, []);

  return (
    <>
      <SEO seoData={seo} />
      <Layout>
        {hero && <Hero data={hero} isHomePage />}
        <div>
          {pageContent?.map(data => {
            if (isSanityCtaBlock(data)) {
              return (
                <div key={data._key}>
                  <CtaBlock data={data} />
                </div>
              );
            }

            if (isSanityProductReferenceCta(data)) {
              return <ProductReferenceCta data={data} key={data._key} />;
            }

            if (isSanityCategoryPanelPreview(data)) {
              return <CategoryPanelPreview data={data} key={data._key} />;
            }

            if (isSanityColumnContent(data)) {
              return <ColumnContent data={data} key={data._key} />;
            }

            if (isSanityLearnCategoryPreview(data)) {
              return <LearnCategoryPreview data={data} key={data._key} />;
            }

            if (isSanityBlogPreview(data)) {
              return <BlogPreview data={data} key={data._key} />;
            }

            if (isSanityRecipePreview(data)) {
              return <RecipePreview data={data} key={data._key} />;
            }

            return null;
          })}
        </div>
      </Layout>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  {
    allSanityHomePage {
      nodes {
        seo {
          ...sanitySeo
        }
        hero {
          ...sanityHero
        }
        pageContent {
          ... on SanityProductReferenceCta {
            ...sanityProductReferenceCta
          }
          ... on SanityCategoryPanelPreview {
            ...sanityCategoryPanelPreview
          }
          ... on SanityColumnContent {
            ...sanityColumnContent
          }
          ... on SanityLearnCategoryPreview {
            ...sanityLearnCategoryPreview
          }
          ... on SanityBlogPreview {
            ...sanityBlogPreview
          }
          ... on SanityRecipePreview {
            ...sanityRecipePreview
          }
        }
      }
    }
  }
`;
